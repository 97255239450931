var buttonTools = $('.btn-tools');

buttonTools.click(function () {
  buttonTools.removeClass('active');
  $(this).addClass('active');
  var idName = this.dataset.id;
  $('.tool-container').hide();
  $('#' + idName).show();
});

var dropdownAnchor = $('.dropdownMenu li a');


document.addEventListener("DOMContentLoaded", function (event) {

  var myParam = location.search.split('tool=')[1];
  $('.tool-container').hide();
  buttonTools.removeClass('active');
  $('.btn-tools[data-id=' + myParam + ']').addClass('active')

  $('#' + myParam).show();


});