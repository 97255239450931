// Menu

var burger = $('#burger');
var menu = $('.menu');
var submenu = $('.drop');


burger.click(function () {
  menu.toggleClass('open');
  burger.toggleClass('x');
  submenu.removeClass('active')
});

submenu.on('click', function () {
$(this).toggleClass('active');

});

var closeMenu = $('.closeClick');

closeMenu.click(function () {
  burger.removeClass('x');
  menu.removeClass('open');
  submenu.removeClass('active');
});



//Form

$(document).ready(function () {
  var headerLocation = $(location).attr('href');

  if (headerLocation.indexOf('submit') >= 0) {
    var message = $('.contact-formular h2');
    var form = $('.contact-formular form');
    message.html("Formular erfolgreich übertragen");
    message.css({ 'color': '#3BAA48' });
    form.css({ 'opacity': '0.5' });

  }
});

// submenu.click(function () {
//
//   $(this).toggleClass('active');
//
// });
