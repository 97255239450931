var gaProperty = 'UA-176286331-1';
var disableStr = 'ga-disable-' + gaProperty; 
if (document.cookie.indexOf(disableStr + '=true') > -1) { 
    window[disableStr] = true;
} 
function gaOptout() { 
    document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/'; 
    window[disableStr] = true; 
    alert('Das Tracking ist jetzt deaktiviert'); 
} 
(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){ 
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o), 
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m) 
})(window,document,'script','https://www.google-analytics.com/analytics.js','ga'); 

ga('create', 'UA-176286331-1', 'auto'); 
ga('set', 'anonymizeIp', true); 
ga('send', 'pageview'); 