$("#slideshow > div:gt(0)").hide();

setInterval(function() {
// console.log('test');

  $('#slideshow > div:first')
  .fadeOut()
  .next()
  .fadeIn()
  .end()
  .appendTo('#slideshow');
}, 3500);
