const skyline = {

	showLightbox: (str, brand) => { 
		const images = str.split(',')
		images.pop()
		const modal = document.createElement('div')
		modal.className = 'skyline__modal'
		modal.innerHTML = `
				<div class="skyline__modal-content">
					<span style="color: black" class="close" onclick="skyline.hideLightbox('.skyline__modal')">&times; schließen</span>
					<div class="skyline__lightbox-slider">
						${skyline.createImages(images, brand)}
					</div>
				</div>
			`
		document.body.appendChild(modal)
		skyline.initSlider()
	},

	createImages: (arr, brand) => { 
		let imageTags = ''
		arr.map(i => imageTags += `<div class="slide"><img width="1400" height="700" src="assets/img/brillen/${brand}/${i}" alt="${brand}"/></div>`)
		return imageTags
	},

	hideLightbox: modal => { 
		document.body.removeChild(document.querySelector(modal))
	},

	initSlider: () => { 
		$('.skyline__lightbox-slider').slick({
			arrows: true,
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 1
		});
	},

	registerEventListener: () => { 
		window.addEventListener('keyup', (e) => { 
			if (e.key === 'Escape') {
				skyline.hideLightbox('.skyline__modal')				
			}
		})
	}
}

skyline.registerEventListener()